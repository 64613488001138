<template>
  <div class="mx-5">
    <campaign-navbar>
      <div class="d-flex" slot="right">
        <div class="status-label mr-2">Active:</div>
        <div class="custom-control custom-switch">
          <input @change="updateCampaignStatus" type="checkbox" class="custom-control-input" id="statusSwitch"
            :checked="campaign.isActive" />
          <label class="custom-control-label" for="statusSwitch"></label>
        </div>
      </div>

      <div class="navbar-menu campaign-menu">
        <div class="d-flex">
          <router-link v-for="tab in tabs" @click.native="setActiveTab(tab.name)" :key="tab.name" class="navbar-item"
            :to="{ name: tab.name }" active-class="active">
            <sprite-icon :icon="tab.icon.name" class="mr-1" :rotate="tab.icon.rotate" fixed-width />
            {{ tab.title }}
          </router-link>
        </div>
      </div>

      <slot name="fab" />
    </campaign-navbar>

    <b-container class="view-campaign-main px-5">
      <slot />
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NProgress from 'nprogress';
import { ALL_CAMPAIGNS_QUERY } from '~/graphql/queries';
import CampaignNavbar from '~/components/CampaignNavbar';
import { CHANGE_CAMPAIGN_STATUS_MUTATION } from '~/graphql/mutations';

export default {
  components: { CampaignNavbar },

  data: function () {
    return {
      activeTab: 'campaign.stats',
      tabs: [
        {
          title: 'Posts',
          name: 'campaign.posts',
          icon: {
            name: 'list-alt',
            rotate: 0,
          },
        },
        // {
        //   title: 'Stats',
        //   name: 'campaign.stats',
        //   icon: {
        //     name: 'stats',
        //     rotate: 0,
        //   },
        // },
        // {
        //   title: 'Logs',
        //   name: 'campaign.logs',
        //   icon: {
        //     name: 'bars',
        //     rotate: 270,
        //   },
        // },
      ],
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      campaign: state => state.campaign.viewing,
    }),
  },

  mounted() {
    this.activeTab = this.$route.name;
  },

  methods: {
    setActiveTab(name) {
      this.activeTab = name;
    },

    async updateCampaignStatus(e) {
      NProgress.start();
      await this.$apollo.mutate({
        mutation: CHANGE_CAMPAIGN_STATUS_MUTATION,
        variables: {
          workspace: this.workspace.id,
          id: this.campaign.id,
          status: e.target.checked ? 'active' : 'inactive',
        },
        refetchQueries: [
          {
            query: ALL_CAMPAIGNS_QUERY,
            variables: {
              workspace: this.workspace.id,
            },
          },
        ],
      });
      NProgress.done();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.view-campaign-main {
  background-color: $white;
  min-height: 80vh;
}

.campaign-menu a {
  padding: 8px 23px 12px;
  font-size: 18px;
  text-align: center;
  color: #4a556b !important;
  border-bottom: 4px solid #f8fafd;
  transition: all 0.2s;
  text-decoration: none;

  &:hover {
    background-color: transparent !important;
    color: lighten(#4a556b, 10) !important;
  }

  &.active {
    color: $primary !important;
    border-color: $primary;
  }
}
</style>
